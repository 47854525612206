import { clamp, nth } from 'lodash';
import { PICTURES_FILE_TYPES } from 'utils/assets';
import { characterPoints, SCORE, STORY_SETTING_PROP, SYSTEM_FONTS } from 'common/constants';
import { UNIFORM_PROPS, CSS_PROPS, OTHER_PROPS } from 'common/constants/component-props';
import { DateDelimiter, DateFormat } from 'client/components/common/BuildingBlocks/Fields/types';
import { LottieDirection } from 'client/components/common/BuildingBlocks/Lottie/utils';
import { SortableMode } from 'client/components/common/BuildingBlocks/SortableBox/constants';
import { SliderDir, SliderEffect, PaginationType } from 'client/components/common/BuildingBlocks/Slider/constants';
import {
	OverlayType,
	OverlayShowOn,
	OverlayAnimationPreset,
} from 'client/components/common/BuildingBlocks/Overlay/constants';
import {
	CounterAnimation,
	CounterDivider,
	CounterType,
} from 'client/components/common/BuildingBlocks/Counter/constants';
import type { PropFieldType } from 'admin/components/pages/Story/CardEditor/Inspector/PropField';
import { parseScore } from 'admin/components/pages/Story/CardEditor/Inspector/CardSettings/CardSettingsModal/utils';
import { PROP_FIELD_TYPES } from '../PropField/constants';
import { PFDatePickerProps } from '../PropField/Date';

/*
 This file contains the basic configuration for inputs by type (PropField),
 which will subsequently be displayed in <InspectorToolbar>, as well as the configuration of input
 according to type, for specific editable properties of the building block.
 */

export const PROP_FIELD_ICONS = {
	FONT_SIZE: 'fontSize',
	LINE_HEIGHT: 'lineHeight',
	TEXT_ALIGN_LEFT: 'textAlignLeft',
	TEXT_ALIGN_RIGHT: 'textAlignRight',
	TEXT_ALIGN_CENTER: 'textAlignCenter',
	DIRECTION_LTR: 'ltr',
	DIRECTION_RTL: 'rtl',
	BOX_FRAME: 'pf-box-frame',
	BOX_STACK_X: 'pf-box-stack-x',
	BOX_STACK_Y: 'pf-box-stack-y',
	BOX_GRID: 'pf-box-grid',
	CELL_HEIGHT: 'pf-cell-height',
	CELL_WIDTH: 'pf-cell-width',
	GAP_ROW: 'pf-gap-row',
	GAP_COL: 'pf-gap-col',
	SPACE_X: 'pf-space-x',
	SPACE_Y: 'pf-space-y',
	SPACE_LEFT: 'pf-space-left',
	SPACE_RIGHT: 'pf-space-right',
	SPACE_TOP: 'pf-space-top',
	SPACE_BOTTOM: 'pf-space-bottom',
	SHAPE_RECT: 'pf-shape-rect',
	SHAPE_CIRCLE: 'pf-shape-circle',
	SHAPE_LINE: 'pf-shape-line',
	STRETCH_X: 'pf-stretch-x',
	STRETCH_Y: 'pf-stretch-y',
	SQUARED_BTN: 'pf-squared-btn',
	ROUNDED_BTN: 'pf-rounded-btn',
	INPUT_TEXT: 'pf-input-text',
	INPUT_EMAIL: 'pf-input-email',
	INPUT_TEL: 'pf-input-tel',
	INPUT_DATE: 'pf-input-date',
	SHARE_EMAIL: 'pf-share-email',
	SHARE_FB: 'pf-share-fb',
	SHARE_WA: 'pf-share-wa',
	SHARE_TW: 'pf-share-tw',
	TIMER_NUM: 'pf-timer-num',
	PROGRESS_BAR: 'pf-progress-bar',
	PROGRESS_NUM: 'pf-progress-num',
	PROGRESS_CIRC: 'pf-progress-circ',
	GENERAL: 'pf-general',
	BORDERS: 'pf-borders',
	CSS: 'pf-css',
	EMBED: 'pf-embed',
	EFFECTS: 'pf-effects',
	LAYOUT: 'pf-layout',
	POSITION: 'pf-position',
	SETTINGS: 'pf-settings',
	AI: 'pf-ai',
	SIZE: 'pf-size',
	TEXT: 'pf-text',
	LINK: 'pf-link',
	STATE: 'pf-state',
	ERROR: 'pf-error',
	BACKGROUND: 'pf-background',
	SYMBOL: 'pf-symbol',
	ANSWERS: 'pf-answers',
	DATE: 'pf-date',
	AUTO_SIZE: 'pf-auto-size',
	WRAP: 'pf-wrap',
} as const;

export const checkbox = {
	component: PROP_FIELD_TYPES.checkbox,
};

export const toggle = {
	component: PROP_FIELD_TYPES.toggle,
};

export const color = {
	component: PROP_FIELD_TYPES.color,
};

export const text = {
	component: PROP_FIELD_TYPES.text,
	clearable: false,
};

export const textareaAutosize = {
	component: PROP_FIELD_TYPES.textareaAutosize,
	minRows: 1,
};

export const slider: {
	component: typeof PROP_FIELD_TYPES.slider;
	min?: number;
	max?: number;
	step?: number;
	unit?: boolean;
	decimals?: number;
} = {
	component: PROP_FIELD_TYPES.slider,
	step: 1,
	unit: false,
	decimals: 0,
};

export const number: {
	component: typeof PROP_FIELD_TYPES.number;
	min: number | undefined;
	max: number | undefined;
	step: number;
	dragStep: number | undefined;
	shiftStep: number;
	decimals: number;
	unit: boolean;
	parse?: (value: string, name: string) => string;
} = {
	component: PROP_FIELD_TYPES.number,
	min: undefined,
	max: undefined,
	step: 1,
	dragStep: undefined,
	shiftStep: 10,
	decimals: 0,
	unit: true, // vw
};

export const select = {
	component: PROP_FIELD_TYPES.select,
	placeholder: 'Select...',
};

export const iconSelect = {
	component: PROP_FIELD_TYPES.iconSelect,
};

export const media = {
	component: PROP_FIELD_TYPES.media,
	customData: { isRemovable: true, fileTypes: PICTURES_FILE_TYPES, minimized: false },
	showLink: false,
};

export const radio = {
	component: PROP_FIELD_TYPES.radio,
};

const fontFamily = {
	...select,
	options: [
		// system default font
		{ label: 'Assistant', value: 'Assistant, sans-serif', italic: true },
		// system optional fonts
		{ label: 'Varela Round', value: SYSTEM_FONTS.VARELA_ROUND.fontFamily, italic: true },
		{ label: 'Miriam Libre', value: SYSTEM_FONTS.MIRIAM_LIBRE.fontFamily, italic: true },
		{ label: 'Suez One', value: SYSTEM_FONTS.SUEZ_ONE.fontFamily, italic: true },
		{ label: 'Secular One', value: SYSTEM_FONTS.SECULAR_ONE.fontFamily, italic: true },
		{ label: 'Alef', value: SYSTEM_FONTS.ALEF.fontFamily, italic: true },
		{ label: 'Rubik', value: SYSTEM_FONTS.RUBIK.fontFamily, italic: true },
	],
};
export const fontWeight = {
	...select,
	options: [
		// must match to system default font loaded in the app
		{ label: 'normal', value: '400' },
		{ label: 'light', value: '300' },
		{ label: 'bold', value: '700' },
		{ label: 'extra bold', value: '800' },
	],
};
const fontSize = { ...number, min: 0, icon: PROP_FIELD_ICONS.FONT_SIZE };
const lineHeight = { ...number, min: 0, dragStep: 2, icon: PROP_FIELD_ICONS.LINE_HEIGHT };
const textAlign = {
	...iconSelect,
	options: [
		{ value: 'left', icon: PROP_FIELD_ICONS.TEXT_ALIGN_LEFT },
		{ value: 'center', icon: PROP_FIELD_ICONS.TEXT_ALIGN_CENTER },
		{ value: 'right', icon: PROP_FIELD_ICONS.TEXT_ALIGN_RIGHT },
	],
};
const direction = {
	...iconSelect,
	options: [
		{ value: 'ltr', icon: PROP_FIELD_ICONS.DIRECTION_LTR },
		{ value: 'rtl', icon: PROP_FIELD_ICONS.DIRECTION_RTL },
	],
};
const padding = { ...number, min: 0 };

const height = { ...number, min: 0, icon: PROP_FIELD_ICONS.CELL_HEIGHT };

type BorderPropFields =
	| 'border'
	| 'borderColor'
	| 'borderStyle'
	| 'borderWidth'
	| 'borderRadius'
	| 'borderTopRightRadius'
	| 'borderTopLeftRadius'
	| 'borderBottomLeftRadius'
	| 'borderBottomRightRadius';
const borderFields: PropFieldsConfig<BorderPropFields> = {
	border: textareaAutosize,
	borderColor: color,
	borderStyle: {
		...select,
		options: [
			{ label: 'all', value: 'solid' },
			{ label: 'top', value: 'solid none none none' },
			{ label: 'right', value: 'none solid none none' },
			{ label: 'bottom', value: 'none none solid none' },
			{ label: 'left', value: 'none none none solid' },
			{ label: 'left/right', value: 'none solid none' },
			{ label: 'top/bottom', value: 'solid none' },
		],
	},
	borderWidth: { ...number, min: 0 },
	borderRadius: { ...number, min: 0 },
	borderTopRightRadius: { ...number, min: 0 },
	borderTopLeftRadius: { ...number, min: 0 },
	borderBottomLeftRadius: { ...number, min: 0 },
	borderBottomRightRadius: { ...number, min: 0 },
};

export const date: {
	component: typeof PROP_FIELD_TYPES.date;
} & Pick<PFDatePickerProps, 'minDate' | 'maxDate' | 'mode'> = {
	component: PROP_FIELD_TYPES.date,
	mode: 'date',
};

type PropFieldsConfig<T extends string = string> = Record<
	T,
	Omit<PropFieldType['fieldProps'], 'name'> & {
		parse?: (value: string, name: string) => string;
		format?: (value: string, name: string) => string | number;
		icon?: (typeof PROP_FIELD_ICONS)[keyof typeof PROP_FIELD_ICONS];
	}
>;

export const propFieldsConfig: PropFieldsConfig = {
	// Video
	videoSrc: media,
	videoPoster: media,
	muted: toggle,
	loop: toggle,
	controls: toggle,
	autoplay: toggle,

	// Background
	background: textareaAutosize,
	backgroundColor: color,
	backgroundSize: {
		...iconSelect,
		options: [
			{ label: 'Fill', value: 'cover' },
			{ label: 'Fit', value: 'contain' },
		],
	},
	objectFit: {
		...iconSelect,
		options: [
			{ label: 'Fill', value: 'cover' },
			{ label: 'Fit', value: 'contain' },
		],
	},
	backgroundRepeat: {
		...select,
		options: [
			{ label: 'repeat', value: 'repeat' },
			{ label: 'no-repeat', value: 'no-repeat' },
			{ label: 'repeat-x', value: 'repeat-x' },
			{ label: 'repeat-y', value: 'repeat-y' },
		],
	},
	backgroundPosition: {
		...select,
		options: [
			{ label: 'top', value: '50% 0%' },
			{ label: 'top-left', value: '0% 0%' },
			{ label: 'top-right', value: '100% 0%' },
			{ label: 'center', value: '50% 50%' },
			{ label: 'center-left', value: '0% 50%' },
			{ label: 'center-right', value: '100% 50%' },
			{ label: 'bottom', value: '50% 100%' },
			{ label: 'bottom-left', value: '0% 100%' },
			{ label: 'bottom-right', value: '100% 100%' },
		],
	},
	backgroundImage: media,
	// Padding
	padding: text,
	paddingTop: padding,
	paddingBottom: padding,
	paddingLeft: padding,
	paddingRight: padding,
	// Margin
	margin: text,
	marginTop: number,
	marginBottom: number,
	marginLeft: number,
	marginRight: number,
	// Grid
	gridTemplateRows: { ...number, min: 0 },
	gridTemplateColumns: {
		...number,
		min: 1,
		max: 24,
		unit: false, // no vw
		// view
		format: (value = '', name) => {
			const s = nth(value.toString().match(/^[^\d]*(\d+)/), 1) ?? '1';
			return parseInt(s, 10);
		},
		// store
		parse: (value = '', name) => {
			return `repeat(${clamp(+value, 1, 24)}, 1fr)`;
		},
	},
	gridAutoRows: { ...number, min: 0, icon: PROP_FIELD_ICONS.CELL_HEIGHT },
	gap: { ...number, min: 0 },
	rowGap: { ...number, min: 0, icon: PROP_FIELD_ICONS.GAP_ROW },
	columnGap: { ...number, min: 0, icon: PROP_FIELD_ICONS.GAP_COL },
	gridAutoColumns: text,
	gridRow: text,
	gridColumn: text,
	// Box
	display: {
		...select,
		options: [
			{ label: 'block', value: 'block' },
			{ label: 'inline-block', value: 'inline-block' },
			{ label: 'flex', value: 'flex' },
			{ label: 'grid', value: 'grid' },
			{ label: 'none', value: 'none' },
		],
	},
	width: { ...number, min: 0, icon: PROP_FIELD_ICONS.CELL_WIDTH },
	minWidth: { ...number, min: 0 },
	maxWidth: { ...number, min: 0 },
	height,
	minHeight: { ...number, min: 0 },
	maxHeight: { ...number, min: 0 },
	...borderFields,
	boxShadow: { ...text, saveOn: 'blur' },
	textShadow: { ...text, saveOn: 'blur' },
	overflow: {
		...select,
		options: [
			{ label: 'auto', value: 'auto' },
			{ label: 'hidden', value: 'hidden' },
			{ label: 'scroll', value: 'scroll' },
			{ label: 'visible', value: 'visible' },
		],
	},
	// Text
	color,
	fontFamily,
	fontWeight,
	fontSize,
	lineHeight,
	textAlign,
	direction,
	textTransform: {
		...select,
		options: [
			{ label: 'capitalize', value: 'capitalize' },
			{ label: 'uppercase', value: 'uppercase' },
			{ label: 'lowercase', value: 'lowercase' },
			{ label: 'none', value: 'none' },
		],
	},
	letterSpacing: { ...number },
	[CSS_PROPS.text.lineClamp]: { ...number, min: 1, unit: false, placeholder: 'auto' },
	[CSS_PROPS.text.lineClampColor]: color,
	// Position
	position: {
		...select,
		options: [
			{ label: 'static', value: 'static' },
			{ label: 'relative', value: 'relative' },
			{ label: 'absolute', value: 'absolute' },
			{ label: 'fixed', value: 'fixed' },
		],
	},
	top: number,
	bottom: number,
	left: number,
	right: number,
	zIndex: number,
	float: {
		...select,
		options: [
			{ label: 'left', value: 'left' },
			{ label: 'right', value: 'right' },
			{ label: 'none', value: 'none' },
		],
	},
	clear: {
		...select,
		options: [
			{ label: 'left', value: 'left' },
			{ label: 'right', value: 'right' },
			{ label: 'both', value: 'both' },
			{ label: 'none', value: 'none' },
		],
	},
	// Flex
	flexDirection: {
		...iconSelect,
		options: [
			{ value: 'column', icon: 'pf-box-stack-y', isDefault: true },
			{ value: 'row', icon: 'pf-box-stack-x' },
		],
	},
	flexWrap: {
		...iconSelect,
		options: [{ value: 'wrap', toggleValue: 'nowrap', icon: 'pf-wrap' }],
	},
	alignItems: {
		...select,
		options: [
			{ value: 'flex-start', label: 'start' },
			{ value: 'flex-end', label: 'end' },
			{ value: 'center', label: 'center' },
			{ value: 'stretch', label: 'stretch' },
		],
	},
	justifyContent: {
		...select,
		options: [
			{ value: 'flex-start', label: 'start' },
			{ value: 'flex-end', label: 'end' },
			{ value: 'center', label: 'center' },
			// { value: 'stretch', label: 'stretch' },
		],
	},
	flexGrow: text,
	flexShrink: text,
	flexBasis: text,
	opacity: {
		...slider,
		min: 0,
		max: 1,
		step: 0.01,
		decimals: 2,
		unit: false,
	},
	transform: textareaAutosize,
	transition: text,
	filter: { ...text, saveOn: 'blur' },
	cursor: { ...select, options: [] },
	pointerEvents: {
		...select,
		options: [
			{ value: 'none', label: 'off' },
			{ value: 'auto', label: 'on' },
		],
	},
	// svg
	[CSS_PROPS.svg.strokeWidth]: { ...number, min: 0 },
	// Custom
	[CSS_PROPS.custom.__CSS]: textareaAutosize,
	tag: {
		...select,
		options: [
			{ value: 'h1', label: 'h1' },
			{ value: 'h2', label: 'h2' },
			{ value: 'h3', label: 'h3' },
			{ value: 'h4', label: 'h4' },
			{ value: 'h5', label: 'h5' },
			{ value: 'h6', label: 'h6' },
			{ value: 'p', label: 'p' },
		],
	},
	src: text,
	tint: color,
	media,
	[OTHER_PROPS.contentLayout.contentHeight]: height,
	[OTHER_PROPS.contentLayout.contentMinHeight]: height,
	contentAlignY: radio,
	contentMarginTop: number,
	contentMarginBottom: number,
	contentMarginLeft: number,
	contentMarginRight: number,
	embedCode: {
		...textareaAutosize,
		// view
		format: (value = '', name) => {
			return decodeURI(value);
		},
		// store
		parse: (value = '', name) => {
			return encodeURI(value);
		},
	},
	[CSS_PROPS.shape.lineThickness]: {
		...number,
		min: 0,
	},
	[UNIFORM_PROPS.fieldType]: { ...iconSelect },
	[UNIFORM_PROPS.fieldFullName]: { ...toggle },
	[UNIFORM_PROPS.fieldRequired]: { ...toggle },
	[UNIFORM_PROPS.fieldPlaceholder]: { ...text, placeholder: 'text..', saveOn: 'blur' },
	[UNIFORM_PROPS.fieldErrorShow]: { ...select },
	[UNIFORM_PROPS.selectIsMulti]: { ...toggle },
	[UNIFORM_PROPS.selectOptions]: { ...text },
	[UNIFORM_PROPS.selectOptionsOrder]: { ...select },
	[UNIFORM_PROPS.btnLink]: { ...text, placeholder: 'https://...', clearable: true },
	[UNIFORM_PROPS.btnNavLink]: { ...select, options: [] },
	[UNIFORM_PROPS.title]: { ...text, saveOn: 'blur' },
	[UNIFORM_PROPS.alt]: { ...text, saveOn: 'blur' },
	[UNIFORM_PROPS.btnBlockLink]: { ...select, options: [] },
	[UNIFORM_PROPS.blockLinkTarget]: { ...toggle },
	[UNIFORM_PROPS.dpFormat]: {
		...select,
		placeholder: DateFormat.DDMMYYYY,
		unit: false,
		options: [
			{ label: DateFormat.DDMMYYYY, value: DateFormat.DDMMYYYY },
			{ label: DateFormat.MMDDYYYY, value: DateFormat.MMDDYYYY },
			{ label: DateFormat.YYYYMMDD, value: DateFormat.YYYYMMDD },
		],
	},
	[UNIFORM_PROPS.dpDelimiter]: {
		...select,
		placeholder: DateDelimiter['.'],
		unit: false,
		options: [
			{ label: DateDelimiter['.'], value: DateDelimiter['.'] },
			{ label: DateDelimiter['/'], value: DateDelimiter['/'] },
			{ label: DateDelimiter['-'], value: DateDelimiter['-'] },
		],
	},
	[UNIFORM_PROPS.telCountry]: {
		...select,
		placeholder: 'Select...',
		unit: false,
	},
	[STORY_SETTING_PROP.score]: {
		...number,
		min: SCORE.MIN,
		max: SCORE.MAX,
		unit: false,
		// store
		parse: (value = '') => `${parseScore(value)}`,
	},
	[STORY_SETTING_PROP.answersMinMax]: {
		...slider,
		unit: false,
	},
	[STORY_SETTING_PROP.forceSubmitBtn]: toggle,
	[characterPoints]: {
		...number,
		min: 0,
		decimals: 1,
		unit: false,
	},
	[STORY_SETTING_PROP.showCorrect]: toggle,
	[STORY_SETTING_PROP.showResults]: toggle,
	[CSS_PROPS.custom.trackColor]: color,
	[CSS_PROPS.custom.progressColor]: color,
	[CSS_PROPS.custom.progressDir]: { ...iconSelect },
	// timer custom props
	[UNIFORM_PROPS.timerType]: iconSelect,
	[UNIFORM_PROPS.timerTime]: { ...number, unit: false, decimals: 2, min: 0, max: 180 },
	// answer progress custom props
	[UNIFORM_PROPS.answerProgressType]: iconSelect,
	[UNIFORM_PROPS.shareTarget]: select,
	[UNIFORM_PROPS.shareCustomUrl]: { ...text, placeholder: 'https://...', saveOn: 'blur' },
	[UNIFORM_PROPS.shareOgSource]: select,
	[UNIFORM_PROPS.metaType]: select,
	// Field error
	[CSS_PROPS.field.errorStyle.text.color]: color,
	[CSS_PROPS.field.errorStyle.text.fontFamily]: fontFamily,
	[CSS_PROPS.field.errorStyle.text.fontWeight]: fontWeight,
	[CSS_PROPS.field.errorStyle.text.fontSize]: fontSize,
	[CSS_PROPS.field.errorStyle.text.lineHeight]: lineHeight,
	[CSS_PROPS.field.errorStyle.text.textAlign]: textAlign,
	[CSS_PROPS.field.errorStyle.text.direction]: direction,
	[CSS_PROPS.field.errorStyle.padding.left]: padding,
	[CSS_PROPS.field.errorStyle.padding.right]: padding,
	[CSS_PROPS.field.errorStyle.padding.top]: padding,
	[CSS_PROPS.field.errorStyle.padding.bottom]: padding,
	[CSS_PROPS.field.style.padding.left]: padding,
	[CSS_PROPS.field.style.padding.right]: padding,
	[CSS_PROPS.field.style.padding.top]: padding,
	[CSS_PROPS.field.style.padding.bottom]: padding,
	[CSS_PROPS.field.style.text.phColor]: color,
	[CSS_PROPS.field.style.box.fill]: color,
	[CSS_PROPS.field.style.box.stroke]: color,
	[CSS_PROPS.field.style.box.iconColor]: color,
	[OTHER_PROPS.lottie.ltSrc]: {
		...media,
		customData: { isRemovable: true, fileTypes: ['.json'], minimized: false },
	},
	[OTHER_PROPS.lottie.ltSpeed]: { ...slider, min: 0.5, max: 3, decimals: 1, step: 0.5, unit: false },
	[OTHER_PROPS.lottie.ltDir]: {
		...select,
		options: [
			{ label: 'Forward', value: LottieDirection.forward },
			{ label: 'Backward', value: LottieDirection.backward },
		],
	},
	[OTHER_PROPS.lottie.ltKeepRatio]: toggle,
	[OTHER_PROPS.lottie.ltLoop]: toggle,
	[OTHER_PROPS.lottie.ltTrigger]: select,
	[OTHER_PROPS.lottie.ltContainer]: select,
	[OTHER_PROPS.lottie.ltOffset]: { ...slider, min: 0, max: 100, decimals: 0, step: 1, unit: false },
	[OTHER_PROPS.overlay.overlayVisible]: toggle,
	[OTHER_PROPS.overlay.overlayBackdropVisible]: toggle,
	[OTHER_PROPS.overlay.overlayBackdrop]: color,
	[UNIFORM_PROPS.overlayTriggers]: text, // custom object, handled in a specific way
	[OTHER_PROPS.overlay.overlayAnimationPreset]: {
		...select,
		options: [
			{ label: 'Fade', value: OverlayAnimationPreset.fade },
			{ label: 'Slide up', value: OverlayAnimationPreset.slideUp },
			{ label: 'Slide down', value: OverlayAnimationPreset.slideDown },
			{ label: 'Slide left', value: OverlayAnimationPreset.slideLeft },
			{ label: 'Slide right', value: OverlayAnimationPreset.slideRight },
		],
	},
	[UNIFORM_PROPS.overlayType]: {
		...select,
		options: [
			{ label: 'Fixed (modal)', value: OverlayType.fixed },
			{ label: 'Relative (tooltip, menu)', value: OverlayType.relative },
		],
	},
	[UNIFORM_PROPS.overlayShowOn]: {
		...select,
		options: [
			{ label: 'Tap', value: OverlayShowOn.tap },
			{ label: 'Hover', value: OverlayShowOn.hover },
		],
	},
	[UNIFORM_PROPS.sortableStartItems]: {
		...text,
		placeholder: '1, ...',
		saveOn: 'blur',
		parse: (value = '') => {
			// remove non-digits and consecutive commas
			return value.replace(/[^0-9,]/g, '').replace(/,+/g, ',');
		},
	},
	[UNIFORM_PROPS.sortableNavTimeout]: { ...number, min: 0, decimals: 1, unit: false },
	[OTHER_PROPS.sortable.dragContainerOffset]: { ...number, min: 0, placeholder: '0' },
	[UNIFORM_PROPS.sortableOrder]: text,
	[UNIFORM_PROPS.sortableMode]: {
		...select,
		options: [
			{ label: 'Random', value: SortableMode.random },
			{ label: 'Custom', value: SortableMode.custom },
		],
	},
	[UNIFORM_PROPS.collectionId]: select,
	// Counter
	[UNIFORM_PROPS.counterType]: {
		...select,
		options: [
			{ label: 'Count Up', value: CounterType.CountUp },
			{ label: 'Count Down', value: CounterType.CountDown },
		],
	},
	[UNIFORM_PROPS.counterDate]: {
		...date,
		placeholder: 'YYYY-MM-DD HH:mm',
		saveOn: 'blur',
	},
	[OTHER_PROPS.counter.counterUnits]: text,
	[OTHER_PROPS.counter.counterDivider]: {
		...select,
		options: [
			{ label: 'Colon', value: CounterDivider.Colon },
			{ label: 'Empty', value: CounterDivider.Empty },
		],
	},
	[OTHER_PROPS.counter.counterAnimation]: {
		...select,
		options: [
			{ label: 'Swipe down', value: CounterAnimation.SwipeDown },
			{ label: 'Swipe up', value: CounterAnimation.SwipeUp },
			{ label: 'Fade', value: CounterAnimation.Fade },
			{ label: 'None', value: CounterAnimation.None },
		],
	},
	[OTHER_PROPS.counter.counterLabels]: toggle,
	[CSS_PROPS.counter.gap]: number,
	[CSS_PROPS.counter.unit.padding.top]: padding,
	[CSS_PROPS.counter.unit.padding.right]: padding,
	[CSS_PROPS.counter.unit.padding.bottom]: padding,
	[CSS_PROPS.counter.unit.padding.left]: padding,
	[CSS_PROPS.counter.unit.background.backgroundColor]: color,
	...Object.keys(CSS_PROPS.counter.unit.borders).reduce((acc, key) => {
		acc[CSS_PROPS.counter.unit.borders[key]] = borderFields[key];
		return acc;
	}, {}),
	[CSS_PROPS.counter.labelStyle.text.color]: color,
	[CSS_PROPS.counter.labelStyle.text.fontFamily]: fontFamily,
	[CSS_PROPS.counter.labelStyle.text.fontWeight]: fontWeight,
	[CSS_PROPS.counter.labelStyle.text.fontSize]: fontSize,
	[CSS_PROPS.counter.labelStyle.text.lineHeight]: lineHeight,
	[CSS_PROPS.counter.labelStyle.margin.top]: number,
	[OTHER_PROPS.slider.autoplayDelay]: { ...number, min: 0.5, decimals: 2, step: 0.1, unit: false },
	[OTHER_PROPS.slider.speed]: { ...number, min: 0, decimals: 2, step: 0.1, unit: false },
	[OTHER_PROPS.slider.spaceBetween]: { ...number, min: 0 },
	[OTHER_PROPS.slider.slidesPerView]: { ...number, min: 1, unit: false },
	[OTHER_PROPS.slider.sliderDirection]: {
		...select,
		options: [
			{ label: 'Horizontal', value: SliderDir.HORIZONTAL },
			{ label: 'Vertical', value: SliderDir.VERTICAL },
		],
	},
	[OTHER_PROPS.slider.sliderMousewheel]: toggle,
	[OTHER_PROPS.slider.centeredSlides]: toggle,
	[OTHER_PROPS.slider.sliderFreeMode]: toggle,
	[OTHER_PROPS.slider.slideEffect]: {
		...select,
		options: [
			{ label: 'Slide', value: SliderEffect.SLIDE },
			{ label: 'Fade', value: SliderEffect.FADE },
			{ label: 'Flip', value: SliderEffect.FLIP },
			{ label: 'Coverflow', value: SliderEffect.COVERFLOW },
		],
	},
	[OTHER_PROPS.sliderPagination.paginationType]: {
		...select,
		options: [
			{ label: 'Bullets', value: PaginationType.BULLETS },
			{ label: 'Numbers', value: PaginationType.FRACTION },
			{ label: 'Progressbar', value: PaginationType.PROGRESSBAR },
		],
	},
};
