/* eslint-disable max-len */
import { MODAL } from 'admin/constants/common';
import { SET_MODAL } from 'admin/constants/actions';
import type { InfoModalProps } from 'admin/components/common/Modal/InfoModal';
import type { MoveStoryModalData } from 'admin/components/pages/Stories/MoveStoryModal';
import type { EditCardModalData } from 'admin/components/pages/Story/Flow/EditCardModal';
import type { KickOutInfoModalData } from 'admin/components/common/Modal/KickOutInfoModal';
import type { DeleteStoryModalData } from 'admin/components/pages/Stories/DeleteStoryModal';
import type { DeleteCardModalData } from 'admin/components/pages/Story/Flow/DeleteCardModal';
import type { CreateCardModalData } from 'admin/components/pages/Story/Flow/CreateCardModal';
import type { StoryPublishModalData } from 'admin/components/pages/Story/StoryPublish/Modal';
import type { RemoveTeamModalData } from 'admin/components/pages/Settings/Team/RemoveTeamModal';
import type { RenameTeamModalData } from 'admin/components/pages/Settings/Team/RenameTeamModal';
import type { StorySettingsModalData } from 'admin/components/pages/Story/Flow/StorySettingsModal';
import type { EditorsConflictModalData } from 'admin/components/common/StoryCoEditingObserver/EditorsConflictModal';
import type { PropSettingsModalData } from 'admin/components/pages/Story/CardEditor/Inspector/PropSettingsModal';
import type { EditIntegrationModalData } from 'admin/components/pages/Settings/Integration/EditIntegrationModal';
import type { RemoveMemberFromWorkspaceModalData } from 'admin/components/pages/Settings/MembersComponent/MemberPopUp/RemoveMemberFromWorkspaceModal';
import type { CardSettingsModalData } from 'admin/components/pages/Story/CardEditor/Inspector/CardSettings/CardSettingsModal';
import type { CreateNavigationModalData } from 'admin/components/pages/Story/Flow/CreateNavigationModal';
import type { EditorAISettingsModalData } from 'admin/components/pages/Story/CardEditor/Inspector/EditorAISettings/EditorAIModal';
import type { SwitchTeamModalData } from 'src/routes/admin/components/pages/Stories/SwitchTeamModal';
import type { EditSelectOptionsModalData } from 'admin/components/pages/Story/CardEditor/Inspector/FieldSettings/EditSelectOptions/types';
import type { EditOverlaysTriggerModalData } from 'admin/components/pages/Story/CardEditor/Inspector/OverlaySettings/EditOverlayTrigger';
import type { EditSortableBoxOrderModalData } from 'admin/components/pages/Story/CardEditor/Inspector/BoxSettings/EditOrder/types';
import type { MobileAppAuthenticatorBackupCodesModalData } from 'admin/components/pages/Profile/MobileAppAuthenticatorBackupCodesModal';
import type { CmsPropBindModalData } from 'admin/components/pages/Story/CardEditor/Inspector/CmsSettings/CmsPropBindModal';

export type ModalReducerState<ID extends MODAL = MODAL.NONE> = { id: ID; data?: {}; autofocus?: boolean } & (
	| { id: MODAL.NONE; data: {} }
	| { id: MODAL.CREATE_STORY }
	| { id: MODAL.ADD_FONTS }
	| { id: MODAL.CHANGE_USER_EMAIL }
	| { id: MODAL.CHANGE_USER_PASSWORD }
	| { id: MODAL.DELETE_USER_ACCOUNT }
	| { id: MODAL.UPDATE_TEAM_MEMBER }
	| { id: MODAL.DELETE_CARD; data: DeleteCardModalData }
	| { id: MODAL.KICK_OUT_INFO_MODAL; data: KickOutInfoModalData }
	| { id: MODAL.STORY_EDITORS_CONFLICT; data: EditorsConflictModalData }
	| { id: MODAL.REMOVE_MEMBER_FROM_WORKSPACE; data: RemoveMemberFromWorkspaceModalData }
	| { id: MODAL.DELETE_TEAM; data: RemoveTeamModalData }
	| { id: MODAL.RENAME_TEAM; data: RenameTeamModalData }
	| { id: MODAL.DELETE_STORY; data: DeleteStoryModalData }
	| { id: MODAL.CARD_SETTINGS; data: CardSettingsModalData }
	| { id: MODAL.PROP_SETTINGS; data: PropSettingsModalData }
	| { id: MODAL.EDITOR_AI; data: EditorAISettingsModalData }
	| { id: MODAL.CREATE_CARD; data: CreateCardModalData }
	| { id: MODAL.EDIT_CARD; data: EditCardModalData }
	| { id: MODAL.EDIT_SELECT_OPTIONS; data: EditSelectOptionsModalData }
	| { id: MODAL.EDIT_OVERLAY_TRIGGERS; data: EditOverlaysTriggerModalData }
	| { id: MODAL.STORY_SETTINGS; data: StorySettingsModalData }
	| { id: MODAL.EDIT_INTEGRATION; data: EditIntegrationModalData }
	| { id: MODAL.STORY_PUBLISH; data: StoryPublishModalData }
	| { id: MODAL.INFORMATION; data?: InfoModalProps }
	| { id: MODAL.MOVE_STORY; data: MoveStoryModalData }
	| { id: MODAL.SWITCH_TEAM; data: SwitchTeamModalData }
	| { id: MODAL.CREATE_NAVIGATION_CARD; data: CreateNavigationModalData }
	| { id: MODAL.EDIT_SORTABLE_BOX_ORDER; data: EditSortableBoxOrderModalData }
	| { id: MODAL.TWO_FA }
	| { id: MODAL.TWO_FA_BACKUP_CODES; data: MobileAppAuthenticatorBackupCodesModalData }
	| { id: MODAL.TWO_FA_REMOVAL }
	| { id: MODAL.WEBHOOK_LOGS; data: { storyId: string; webhookId: string } }
	| { id: MODAL.CMS_PROP_BIND_MODAL; data: CmsPropBindModalData }
);

type Action = { type: typeof SET_MODAL; payload: ModalReducerState };

const initialState: ModalReducerState = {
	id: MODAL.NONE,
	data: {},
	autofocus: true,
};

export default function ModalReducer(state = initialState, action: Action): ModalReducerState {
	switch (action.type) {
		case SET_MODAL:
			return action.payload;

		default:
			return state;
	}
}
