import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form';
import type { AdminReducerState } from 'admin/reducers';
import { StoryFacade } from 'utils/facades/story-facade';
import { FORM_MODEL } from 'admin/constants/common';

export const selectStoryEditor = (state: AdminReducerState) => state.storyEditor;

export const selectEditableStoryForm = (state: AdminReducerState) =>
	getFormValues(FORM_MODEL.EDIT_STORY)(state) as AdminReducerState['storyEditor']['story'];

export const selectEditableStory = createSelector(selectStoryEditor, state => state.story);

export const selectEditableStoryId = createSelector(selectEditableStory, story => story?.id);

export const selectEditableStoryType = createSelector(selectEditableStory, story => story?.type);

export const selectEditableStoryDictionary = createSelector(selectStoryEditor, state => state.dictionary);

// todo: refactor to get rid of "!"
export const selectStoryFacade = createSelector(selectEditableStory, story => new StoryFacade(story!));

export const selectStoryCards = createSelector(selectStoryFacade, facade => facade.cards);

export const selectStoryMediaQuery = createSelector(selectStoryFacade, facade => facade.mediaQuery);

export const selectStoryLang = createSelector(selectStoryFacade, facade => facade.language);

export const selectStorycardsDomain = createSelector(selectEditableStory, story => story?.storycardsDomain);

export const selectStoryGenerator = createSelector(selectStoryFacade, story => story.settings.generator);

export const selectOpCounter = createSelector(selectStoryEditor, story => story.story?.opCounter ?? 1);

export const selectStoryIntegrations = createSelector([selectEditableStory], story => {
	if (!story) return null;
	return new StoryFacade(story).settings.integrations;
});
