import type { ThunkDispatch } from 'redux-thunk';
import { Action, combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';

import user from './user/reducer';
import loading from './loading/reducer';
import modal from './modal-reducer';
import stories from './stories-reducer';
import reportStories from './report-stories-reducer';
import storyEditor from './story-editor/reducer';
import version from './version-reducer';
import cardEditor from './card-editor/reducer';
import cardEditorExtra from './card-editor-extra/reducer';

const rootReducer = combineReducers({
	modal,
	user,
	stories,
	reportStories,
	loading,
	version,
	storyEditor,
	cardEditor,
	cardEditorExtra,
	form: formReducer,
});

export type AdminReducerState = ReturnType<typeof rootReducer>;

export type AdminThunkDispatch<A extends Action = Action> = ThunkDispatch<AdminReducerState, void, A>;

export type AdminThunkAction<A extends Action = Action, R = void> = (
	dispatch: AdminThunkDispatch<A>,
	getState: () => AdminReducerState
) => Promise<R>;

export const useAdminDispatch = () => useDispatch<AdminThunkDispatch>();
export const useAdminSelector: TypedUseSelectorHook<AdminReducerState> = useSelector;

export default rootReducer;
